import React from 'react';
import { Button } from "react-bootstrap";
import ReactGA from 'react-ga4';

function DesktopShopButton() {

    const clickHandlerShopButton = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked shopButton1 button"
        });
    }

    return (
        <>
            <div className="shop-now">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <Button
                            id="shopButton1"
                            target="_blank"
                            href="https://www.ebay.com/str/robodynetoys"
                            className="justify-content-center shop-now-btn p-2 pulse-btn1 white btn text-dark"
                            onClick={clickHandlerShopButton}>Shop Now!</Button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DesktopShopButton