import React from 'react';
import mba_logo from './Content/images/mba_logo.png';
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaEbay } from "react-icons/fa";
import ReactGA from 'react-ga4';

function Footer() {

    const clickHandlerFacebookButton = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked facebookButton2 button"
        });
    }
    const clickHandlerInstagramButton = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked instagramButton2 button"
        });
    }
    const clickHandlerEbayButton = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked ebayButton2 button"
        });
    }
    const clickHandlerSponsorButton = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked sponsorButton button"
        })
    }

    return (
        <>
            <div className="footer">
                <div className="container-fluid pb-0 mb-0 justify-content-center text-light"></div>
                <footer className="text-center text-lg-start text-white">
                    <div className="container p-4 pb-0">
                        <div className="row">
                            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                                <h6 className="text-uppercase mb-4 font-weight-bold bold-text">ROBODYNE TOYS</h6>
                                <p>
                                    A limited collection of vintage toys collected over the years in very good to like new condition!
                                </p>
                            </div>

                            <hr className="w-100 clearfix d-md-none" />

                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                                <h6 className="text-uppercase mb-4 font-weight-bold bold-text">Location</h6>
                                <p><i className="fas fa-home mr-3"></i> Southern Worcester County Massachusetts</p>
                            </div>

                            <hr className="w-100 clearfix d-md-none" />

                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                                <h6 className="text-uppercase mb-4 font-weight-bold bold-text">Sponsored By</h6>
                                <p>Massachusetts Business Associates</p>
                                <a id="sponsorButton" onClick={clickHandlerSponsorButton} href="https://www.massbusinessassociates.com/" target="_blank" rel="noreferrer">
                                    <img src={mba_logo} alt="" className="rounded-pill sponsored-logo" />
                                </a>
                            </div>
                        </div>

                        <hr className="my-3" />

                        <div className="row d-flex justify-content-center p-3 pt-0">
                            <div className="col-lg-4 text-center">
                                <a id="facebookButton2" onClick={clickHandlerFacebookButton} target="_blank" href="https://www.facebook.com/Robodyne-Toys-103617062055871" rel="noreferrer"><FaFacebook size="30" color="white" className="footer-social-media" /></a>
                                <a id="instagramButton2" onClick={clickHandlerInstagramButton} target="_blank" href="/" rel="noreferrer"><FaInstagram size="30" color="white" className="footer-social-media" /></a>
                                <a id="ebayButton2" onClick={clickHandlerEbayButton} target="_blank" href="https://www.ebay.com/str/robodynetoys" rel="noreferrer"><FaEbay size="30" color="white" className="footer-social-media" /></a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}
export default Footer;