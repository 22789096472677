import React, { useState, useEffect } from 'react';
import { Nav, Navbar } from "react-bootstrap";
import ReactGA from 'react-ga4';

function DesktopHeader() {

    /* Header NavBar Animation */
    const [header, setHeader] = useState("header")
    const listenScrollEvent = (event) => {
        if (window.scrollY < 1) {
            return setHeader("header1")
        } else if (window.scrollY > 1) {
            return setHeader("header2")
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    const clickHandlerHomeLink = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked navHomeLink button"
        });
    }
    const clickHandlerCollectionLink = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked navCollectionLink button"
        });
    }
    const clickHandlerAboutUsLink = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked navAboutUsLink button"
        });
    }
    const clickHandlerContactUsLink = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked navContactUsLink button"
        });
    }
    const clickHandlerShopLink = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked navShopLink button"
        });
    }

    return (
        <>
            <Navbar collapseOnSelect expand="lg" fixed="top" className={header}>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="mx-5" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto"></Nav>
                    <Nav className="ml-auto px-5">
                        <Nav.Link id="navHomeLink" onClick={clickHandlerHomeLink} href="#home" className="active mx-3 px-5">Home</Nav.Link>
                        <Nav.Link id="navCollectionLink" onClick={clickHandlerCollectionLink} href="#theCollection" className="mx-3 px-5">Collection</Nav.Link>
                        <Nav.Link id="navAboutUsLink" onClick={clickHandlerAboutUsLink} href="#AboutUs" className="mx-3 px-5">About Us</Nav.Link>
                        <Nav.Link id="navContactUsLink" onClick={clickHandlerContactUsLink} href="#ContactUs" className="mx-3 px-5">Contact</Nav.Link>
                        <Nav.Link id="navShopLink" onClick={clickHandlerShopLink} target="_blank" href="https://www.ebay.com/str/robodynetoys" className="mx-3 px-5">Shop</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}
export default DesktopHeader;