import React from 'react';
import { Button } from "react-bootstrap";
import { Fab, Action } from 'react-tiny-fab';
import { BsFillChatSquareFill } from "react-icons/bs";
import { BsInfoSquareFill } from "react-icons/bs";
import { BsFillGridFill } from "react-icons/bs";
import { BsFillSquareFill } from "react-icons/bs"
import { BsFillXDiamondFill } from "react-icons/bs"
import { BsFillBagFill } from "react-icons/bs";
import ReactGA from 'react-ga4';

function FloatMenu() {

    const clickHandlerFloatMenu = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked main float menu button"
        });
    }
    const clickHandlerHomeLink = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked float menu home button"
        });
    }
    const clickHandlerCollectionLink = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked float menu collection button"
        });
    }
    const clickHandlerAboutUsLink = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked float menu aboutus button"
        });
    }
    const clickHandlerContactUsLink = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked float menu contactus button"
        });
    }
    const clickHandlerShopLink = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked float menu shop button"
        });
    }

    return (
        <>
            <div>
                <Fab
                    icon={<BsFillXDiamondFill size={30} />}
                    alwaysShowTitle={true}
                    mainButtonStyles={{ backgroundColor: '#7C88AD', width: 70, borderRadius: 48 }}
                    onClick={clickHandlerFloatMenu}
                >
                    <Action
                        text="Shop"
                        style={{ backgroundColor: "#7C88AD" }}
                        onClick={clickHandlerShopLink}
                    >
                        <Button className="rounded-circle float-action-btn-shop" target="_blank" href="https://www.ebay.com/str/robodynetoys">
                            <BsFillBagFill />
                        </Button>
                    </Action>

                    <Action
                        text="Home"
                        style={{ backgroundColor: "#D6A419" }}
                        onClick={clickHandlerHomeLink}
                    >
                        <Button className="rounded-circle float-action-btn" href="#home">
                            <BsFillSquareFill />
                        </Button>
                    </Action>

                    <Action
                        text="Collection"
                        style={{ backgroundColor: "#D6A419" }}
                        onClick={clickHandlerCollectionLink}
                    >
                        <Button className="rounded-circle float-action-btn" href="#theCollection">
                            <BsFillGridFill />
                        </Button>

                    </Action>

                    <Action
                        text="About Us"
                        style={{ backgroundColor: "#D6A419" }}
                        onClick={clickHandlerAboutUsLink}
                    >
                        <Button className="rounded-circle float-action-btn" href="#AboutUs">
                            <BsInfoSquareFill />
                        </Button>
                    </Action>

                    <Action
                        text="Contact Us"
                        style={{ backgroundColor: "#D6A419" }}
                        onClick={clickHandlerContactUsLink}
                    >
                        <Button className="rounded-circle float-action-btn" href="#ContactUs">
                            <BsFillChatSquareFill />
                        </Button>
                    </Action>
                </Fab>
            </div>
        </>
    )
}
export default FloatMenu