import React from 'react';
import './Content.scss';
import CollectionContent from './CollectionContent';
import DesktopShopButton from './DesktopShopButton';
import WelcomeContent from './WelcomeContent';
import AboutUsContent from './AboutUsContent';
import ContactUsContent from './ContactUsContent';

function Content() {
  return (
    <>
      <DesktopShopButton />
      <WelcomeContent />
      <CollectionContent />
      <AboutUsContent />
      <ContactUsContent />
    </>
  );
}
export default Content;