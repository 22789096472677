import React from 'react';
import { Navbar } from "react-bootstrap";

function MobileHeader() {
    return (
        <>
            <Navbar collapseOnSelect expand="lg" fixed="top" className="mobile-header">
                <div className="container justify-content-center text-center">
                    <Navbar.Brand className=" text-white mobile-header-content" href="#home">
                        <span style={{ color: "#7C88AD" }}>ROBO</span>
                        <span style={{ color: "#D6A419" }}>DYNE </span>
                        <span className="toy-word">TOYS</span>
                    </Navbar.Brand>
                </div>
            </Navbar>
        </>
    )
}
export default MobileHeader;