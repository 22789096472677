import React from 'react';
import Parallax from './Components/Parallax/Parallax';
import Content from './Components/Content/Content';
import Footer from './Components/Footer';
import FloatMenu from './Components/FloatMenu';
import DesktopHeader from './Components/DesktopHeader';
import MobileHeader from './Components/MobileHeader';
import ReactGA from 'react-ga4';

function App() {
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
  ReactGA.send({ hitType: "pageview", page: "main page" });
  console.log("Hi There! This is Robodyne Toys!");

  return (
    <div className="App">
      <FloatMenu />
      <div id="home">
        <div className="d-none d-lg-block">
          <DesktopHeader />
          <Parallax />
        </div>
        <div className="d-lg-none">
          <MobileHeader />
        </div>
      </div>
      <Content />
      <Footer />
    </div>
  );
}

export default App;