import React from 'react';
import { Button } from "react-bootstrap";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaEbay } from "react-icons/fa";
import ReactGA from 'react-ga4';

function WelcomeContent() {

    const clickHandlerFacebookButton = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked facebookButton1 button"
        });
    }
    const clickHandlerInstagramButton = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked instagramButton1 button"
        });
    }
    const clickHandlerEbayButton = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked ebayButton1 button"
        });
    }

    return (
        <>
            <div className="welcome-content">
                <div className="container">
                    <div className="row display-3 p-3 justify-content-center text-center txt-clr-orange">
                        <p>Welcome to</p> ROBODYNE Toys
                    </div>
                    <div className="row p-3 justify-content-center text-center txt-clr-light-blue welcome-subtitle">
                        A limited collection of vintage toys collected over the years in very good to like new condition!
                    </div>
                    <div className="row p-5 justify-content-center text-center">
                        <div className="col">
                            <Button
                                id="shopButton2"
                                target="_blank"
                                href="https://www.ebay.com/str/robodynetoys"
                                className="shop-now-bottom-btn p-2 pulse-btn2 orange btn text-dark">Shop now and see whats available!</Button>
                        </div>
                    </div>
                    <div className="row p-3 pt-0 justify-content-center ">
                        <div className="col-lg-4 text-center">
                            <a id="facebookButton1" onClick={clickHandlerFacebookButton} target="_blank" href="https://www.facebook.com/Robodyne-Toys-103617062055871" rel="noreferrer"><FaFacebook size="30" color="white" className="footer-social-media" /></a>
                            <a id="instagramButton1" onClick={clickHandlerInstagramButton} target="_blank" href="/" rel="noreferrer"><FaInstagram size="30" color="white" className="footer-social-media" /></a>
                            <a id="ebayButton1" onClick={clickHandlerEbayButton} target="_blank" href="https://www.ebay.com/str/robodynetoys" rel="noreferrer"><FaEbay size="30" color="white" className="footer-social-media" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WelcomeContent;