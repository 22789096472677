import React from 'react'
import pic1 from './images/akira_496x226.png';
import pic2 from './images/dragonar_496x226.png';
import pic3 from './images/gundam_496x226.jpg';
import pic4 from './images/orguss_496x226.png';
import pic5 from './images/patlabor_496x226.png';
import pic6 from './images/powerrangers_496x226.png';
import pic7 from './images/robotech_496x226.png';
import pic8 from './images/transformers_496x226.png';
import pic9 from './images/voltron_496x226.png';
import pic10 from './images/tomy_496x226.jpg';
import pic11 from './images/macross_496x226.png';
import { Row, Col, Card, Button } from "react-bootstrap";
import { BsCaretDownFill } from "react-icons/bs";
import ReactGA from 'react-ga4';

function CollectionContent() {

    const clickHandlerAkira = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked collectionShopAkira button"
        });
    }
    const clickHandlerDragonar = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked collectionShopDragonar button"
        });
    }
    const clickHandlerGundam = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked collectionShopGundam button"
        });
    }
    const clickHandlerOrguss = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked collectionShopOrguss button"
        });
    }
    const clickHandlerPatlabor = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked collectionShopPatlabor button"
        });
    }
    const clickHandlerPowerrangers = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked collectionShopPowerrangers button"
        });
    }
    const clickHandlerRobotech = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked collectionShopRobotech button"
        });
    }
    const clickHandlerTransformers = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked collectionShopTransformers button"
        });
    }
    const clickHandlerVoltron = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked collectionShopVoltron button"
        });
    }
    const clickHandlerTomy = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked collectionShopTomy button"
        });
    }
    const clickHandlerMacross = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked collectionShopMacross button"
        });
    }

    return (
        <>
            <div className="collections-content" id="theCollection" >
                <div className="container">
                    <div className="row display-4 p-3 txt-clr-orange justify-content-center">
                        The Collection
                    </div>
                    <p class="rounded-3 p-3 bg-light-blue text-dark">
                        Below you will see the brands of toys that we hold in our limited vintage collection.
                        Check out any brands and shop now to see whats available! 
                    </p>
                    <Row xs={1} sm={2} md={4} className="g-4 pt-3">
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={pic1} />
                                <Card.Body className="p-2">
                                    <Card.Title className="card-title-font-size">Akira<BsCaretDownFill className="float-end" /></Card.Title>
                                    <div className="hover_content">
                                        <div className="container">
                                            <div className="row py-2">
                                                <Button className="btn-sm" target="_blank" href="https://en.wikipedia.org/wiki/Akira_(1988_film)" variant="outline-secondary">Learn More</Button>
                                            </div>
                                            <div className="row py-1">
                                                <Button id="collectionShopAkira" onClick={clickHandlerAkira} variant="outline-primary" className="rounded" target="_blank" href="https://www.ebay.com/str/robodynetoys">Shop</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={pic2} />
                                <Card.Body className="p-2">
                                    <Card.Title className="card-title-font-size">Dragonar<BsCaretDownFill className="float-end" /></Card.Title>
                                    <div className="hover_content">
                                        <div className="container">
                                            <div className="row py-2">
                                                <Button className="btn-sm" target="_blank" href="https://en.wikipedia.org/wiki/Metal_Armor_Dragonar" variant="outline-secondary">Learn More</Button>
                                            </div>
                                            <div className="row py-1">
                                                <Button id="collectionShopDragonar" onClick={clickHandlerDragonar} variant="outline-primary" className="rounded" target="_blank" href="https://www.ebay.com/str/robodynetoys">Shop</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={pic3} />
                                <Card.Body className="p-2">
                                    <Card.Title className="card-title-font-size">Gundam<BsCaretDownFill className="float-end" /></Card.Title>
                                    <div className="hover_content">
                                        <div className="container">
                                            <div className="row py-2">
                                                <Button className="btn-sm" target="_blank" href="https://en.wikipedia.org/wiki/Gundam" variant="outline-secondary">Learn More</Button>
                                            </div>
                                            <div className="row py-1">
                                                <Button id="collectionShopGundam" onClick={clickHandlerGundam} variant="outline-primary" className="rounded" target="_blank" href="https://www.ebay.com/str/robodynetoys">Shop</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={pic4} />
                                <Card.Body className="p-2">
                                    <Card.Title className="card-title-font-size">Orguss<BsCaretDownFill className="float-end" /></Card.Title>
                                    <div className="hover_content">
                                        <div className="container">
                                            <div className="row py-2">
                                                <Button className="btn-sm" target="_blank" href="https://en.wikipedia.org/wiki/Super_Dimension_Century_Orguss" variant="outline-secondary">Learn More</Button>
                                            </div>
                                            <div className="row py-1">
                                                <Button id="collectionShopOrguss" onClick={clickHandlerOrguss} variant="outline-primary" className="rounded" target="_blank" href="https://www.ebay.com/str/robodynetoys">Shop</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={pic5} />
                                <Card.Body className="p-2">
                                    <Card.Title className="card-title-font-size">PatLabor<BsCaretDownFill className="float-end" /></Card.Title>
                                    <div className="hover_content">
                                        <div className="container">
                                            <div className="row py-2">
                                                <Button className="btn-sm" target="_blank" href="https://en.wikipedia.org/wiki/Patlabor" variant="outline-secondary">Learn More</Button>
                                            </div>
                                            <div className="row py-1">
                                                <Button id="collectionShopPatlabor" onClick={clickHandlerPatlabor} variant="outline-primary" className="rounded" target="_blank" href="https://www.ebay.com/str/robodynetoys">Shop</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={pic6} />
                                <Card.Body className="p-2">
                                    <Card.Title className="card-title-font-size">PowerRangers<BsCaretDownFill className="float-end" /></Card.Title>
                                    <div className="hover_content">
                                        <div className="container">
                                            <div className="row py-2">
                                                <Button className="btn-sm" target="_blank" href="https://en.wikipedia.org/wiki/Power_Rangers" variant="outline-secondary">Learn More</Button>
                                            </div>
                                            <div className="row py-1">
                                                <Button id="collectionShopPowerrangers" onClick={clickHandlerPowerrangers} variant="outline-primary" className="rounded" target="_blank" href="https://www.ebay.com/str/robodynetoys">Shop</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={pic7} />
                                <Card.Body className="p-2">
                                    <Card.Title className="card-title-font-size">Robotech<BsCaretDownFill className="float-end" /></Card.Title>
                                    <div className="hover_content">
                                        <div className="container">
                                            <div className="row py-2">
                                                <Button className="btn-sm" target="_blank" href="https://en.wikipedia.org/wiki/Robotech" variant="outline-secondary">Learn More</Button>
                                            </div>
                                            <div className="row py-1">
                                                <Button id="collectionShopRobotech" onClick={clickHandlerRobotech} variant="outline-primary" className="rounded" target="_blank" href="https://www.ebay.com/str/robodynetoys">Shop</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={pic8} />
                                <Card.Body className="p-2">
                                    <Card.Title className="card-title-font-size">Transformers<BsCaretDownFill className="float-end" /></Card.Title>
                                    <div className="hover_content">
                                        <div className="container">
                                            <div className="row py-2">
                                                <Button className="btn-sm" target="_blank" href="https://en.wikipedia.org/wiki/Transformers" variant="outline-secondary">Learn More</Button>
                                            </div>
                                            <div className="row py-1">
                                                <Button id="collectionShopTransformers" onClick={clickHandlerTransformers} variant="outline-primary" className="rounded" target="_blank" href="https://www.ebay.com/str/robodynetoys">Shop</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={pic9} />
                                <Card.Body className="p-2">
                                    <Card.Title className="card-title-font-size">Voltron<BsCaretDownFill className="float-end" /></Card.Title>
                                    <div className="hover_content">
                                        <div className="container">
                                            <div className="row py-2">
                                                <Button className="btn-sm" target="_blank" href="https://en.wikipedia.org/wiki/Voltron" variant="outline-secondary">Learn More</Button>
                                            </div>
                                            <div className="row py-1">
                                                <Button id="collectionShopVoltron" onClick={clickHandlerVoltron} variant="outline-primary" className="rounded" target="_blank" href="https://www.ebay.com/str/robodynetoys">Shop</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={pic10} />
                                <Card.Body className="p-2">
                                    <Card.Title className="card-title-font-size">Tomy<BsCaretDownFill className="float-end" /></Card.Title>
                                    <div className="hover_content">
                                        <div className="container">
                                            <div className="row py-2">
                                                <Button className="btn-sm" target="_blank" href="https://en.wikipedia.org/wiki/Tomy" variant="outline-secondary">Learn More</Button>
                                            </div>
                                            <div className="row py-1">
                                                <Button id="collectionShopTomy" onClick={clickHandlerTomy} variant="outline-primary" className="rounded" target="_blank" href="https://www.ebay.com/str/robodynetoys">Shop</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src={pic11} />
                                <Card.Body className="p-2">
                                    <Card.Title className="card-title-font-size">Macross<BsCaretDownFill className="float-end" /></Card.Title>
                                    <div className="hover_content">
                                        <div className="container">
                                            <div className="row py-2">
                                                <Button className="btn-sm" target="_blank" href="https://en.wikipedia.org/wiki/Macross" variant="outline-secondary">Learn More</Button>
                                            </div>
                                            <div className="row py-1">
                                                <Button id="collectionShopMacross" onClick={clickHandlerMacross} variant="outline-primary" className="rounded" target="_blank" href="https://www.ebay.com/str/robodynetoys">Shop</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

        </>
    )
}
export default CollectionContent;