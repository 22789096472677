import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import pic1 from './images/robotech4.jpg';
import pic2 from './images/robotech3.jpg';
import pic3 from './images/robotech2.jpg';
import pic4 from './images/robotech1.jpg';
import pic5 from './images/transformers.jpg';
import pic6 from './images/gundams.jpg';
import pic7 from './images/gundams1.jpg';
import pic8 from './images/zoids.jpg';
import pic9 from './images/orguss.jpg';
import pic10 from './images/powerrangers.jpg';
import pic11 from './images/tomy.jpg';
import pic12 from './images/books.jpg';
import pic13 from './images/misc.jpg';

function AboutUsContent() {
    return (
        <>
            <div className="aboutus-content" id="AboutUs">
                <div className="container">
                    <div className="row display-4 p-3 txt-clr-orange justify-content-center">
                        About Us
                    </div>
                    <div className="row p-3 aboutus-story text-white">
                        <p class="rounded-3 p-3 bg-light-blue text-dark">
                            Since the days of Macross, Robotech, ForceFive, Transformers, et al, the collection has grown to what it is today. The collection consists mainly of mecha and transformable robots.
                            I stayed away from action figures as these did not interest me.
                            My interest was mainly geared towards transforming robots.
                            <br />
                            <br />
                            As a collector I realized keeping the robots in their original condition would be best. 
                            But being human, the need to open and gaze upon them was far too great and had to succum to displaying a FEW of them.

                            But nonetheless, the major part of the current collection consists of toys which are no longer being produced and are vintage in nature, still with their original boxes, a good portion still sealed, unused and away from any sunlight.
                            <br />
                            <br />
                            I prefer to have serious buyers.
                        </p>
                    </div>
                    <div className="row">
                        <Carousel>
                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={pic1}
                                    alt="First slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={pic2}
                                    alt="Second slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={pic3}
                                    alt="Third slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={pic4}
                                    alt="Third slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={pic5}
                                    alt="Third slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={pic6}
                                    alt="Third slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={pic7}
                                    alt="Third slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={pic8}
                                    alt="Third slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={pic9}
                                    alt="Third slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={pic10}
                                    alt="Third slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={pic11}
                                    alt="Third slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={pic12}
                                    alt="Third slide"
                                />
                            </Carousel.Item>

                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={pic13}
                                    alt="Third slide"
                                />
                            </Carousel.Item>

                        </Carousel>


                    </div>
                </div>
            </div>
        </>
    )
}
export default AboutUsContent;