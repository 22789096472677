import React, { useState } from 'react';
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import { Button } from "react-bootstrap";
import ReactGA from 'react-ga4';

function ContactUsContent() {
    // React-Bootstrap Handle Submit form
    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
    };

    const clickHandlerContactSubmitButton = () => {
        ReactGA.event({
            category: "Button",
            action: "Clicked submit contactusButton button"
        });
    }

    return (
        <>
            <div className="contactus-content" id="ContactUs">
                <div className="container">
                    <div className="row display-4 p-3 txt-clr-orange justify-content-center">
                        Contact Us
                    </div>
                    <div className="row p-3">
                        <div className="form-container p-4 rounded-3 bg-light-blue">
                            <p>Any questions regarding the toy collection, price or availability of an item, please fill out below and we will get back to you as soon as possible.  Thank you!</p>

                            <Form
                                name="contact-v1"
                                method="POST"
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}>

                                <Form.Control type="hidden" name="form-name" value="contact-v1" />

                                <Form.Group className="mb-3" controlId="contact-v1.NameInput">
                                    <FloatingLabel controlId="floatingInput" label="Your Name" className="mb-3">
                                        <Form.Control
                                            required
                                            name="name"
                                            type="text"
                                            placeholder="Normal text" />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your name.
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="contact-v1.EmailInput">
                                    <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
                                        <Form.Control
                                            required
                                            name="email"
                                            type="email"
                                            placeholder="name@example.com" />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter valid email address like (name@example.com).
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="contact-v1.MessageInput">
                                    <FloatingLabel controlId="floatingTextarea" label="Message">
                                        <Form.Control
                                            required
                                            name="message"
                                            as="textarea"
                                            placeholder="Leave a message here"
                                            style={{ height: '150px' }} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a message.
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>

                                <div className="row justify-content-center">
                                    <div className="col col-lg-6 col-md-6">
                                        <Button className="w-100 btn-primary-contact" type="submit" id="contactusButton" onClick={clickHandlerContactSubmitButton}>
                                            Submit
                                        </Button>
                                    </div>
                                </div>

                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactUsContent;