import React, { useEffect } from 'react'
import './Parallax.scss';
// import Particles from 'react-particles-js';

function Parallax() {
  /* Parallax effect configuration */
  useEffect(() => {
    window.addEventListener('scroll', function (e) {
      const layers = Array.from(document.querySelectorAll('.parallax-layer'));
      let topDistance = window.pageYOffset;

      layers.forEach(layer => {
        let speed = layer.getAttribute('data-speed');
        let movement = -((topDistance * speed) / 100);
        layer.style.transform = `translateY(${movement}px)`;
      });
    });
  });

  return (
    <div className="Parallax">

      {/* Base layers for Parallax effect */}
      <div className="parallax-layer" id="layer-0" data-speed="2" />
      <div className="parallax-layer" id="layer-1" data-speed="5" />
      <div className="parallax-layer" id="layer-2" data-speed="11" />
      <div className="parallax-layer" id="layer-3" data-speed="16" />
      <div className="parallax-layer" id="layer-4" data-speed="26" />
      <div className="parallax-layer" id="layer-5" data-speed="36" />
      <div className="parallax-layer" id="layer-6" data-speed="49" />
      <div className="parallax-layer" id="layer-7" data-speed="69" />

      {/* Particle effects */}
      {/* <Particles style={{ position: "fixed" }}
        params={{
          "particles": {
            "number": {
              "value": 450,
              "density": {
                "enable": false
              }
            },
            "size": {
              "value": 3,
              "random": true
            },
            "move": {
              "direction": "",
              "out_mode": "out",
              "speed": 1
            },
            "line_linked": {
              "enable": false
            }
          },
          "interactivity": {
            "events": {
              "onclick": {
                "enable": false,
                "mode": "remove"
              }
            },
            "modes": {
              "remove": {
                "particles_nb": 10
              }
            }
          }
        }} /> */}
    </div>
  );
}
export default Parallax;